<template>
  <div class="extration-bdd-frais">
    <b-button
      size="sm"
      class="button-export-style ml-2"
      v-b-modal.extract-invalid-modal
      title="Télecharger  les factures non validées"
    >
      <font-awesome-icon icon="cloud-download-alt"
    /></b-button>
    <!-- Extract BDD Modal -->
    <b-modal
      ref="extract-invalid-modal"
      id="extract-invalid-modal"
      no-close-on-backdrop
      :hide-footer="true"
      :title="'Générer un ' + typeModal"
      @hidden="hideModal('extract-invalid-modal')"
      modal-class="modal-extaction-bdd-frais"
    >
      <b-form-group
        id="fieldset-horizontal-type"
        label-cols-sm="5"
        label-cols-lg="3"
        content-cols-sm
        content-cols-lg="9"
        label="Type de fichier : "
        label-for="type-modal"
      >
        <b-form-select
          id="fieldset-horizontal-type"
          v-model="typeModal"
          :options="ListType"
          required
          class="b-form-select-raduis"
          disabled
        ></b-form-select>
      </b-form-group>

      <div class="form-modal-custom-style mt-2">
        <div class="messageError">
          <div v-if="error" class="error">
            <ul v-if="Array.isArray(error)">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ error }}</span>
          </div>
        </div>
        <div class="actionModel">
          <b-button
            class="button-valide-style"
            @click="handleExportFileInvalid"
          >
            <span>
              Générer
              <div
                v-if="getLoadingExtactBddFraisVisionFiliale"
                class="loading ml-2"
              >
                <div class="spinner-border" role="status"></div></div
            ></span>
          </b-button>
        </div>
      </div>
    </b-modal>
    <!-- END Extract BDD Modal -->
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    filterType: {
      default: null
    },
    filteryear: { reduired: true },
    filterMonth: { reduired: true },
    dataToUse: { required: true }
  },
  data() {
    return {
      selectedModel: 'standard',
      ListYear: [],
      error: null,
      ListMonth: [
        { value: null, text: 'Tous' },
        { value: 'janvier', text: 'janvier' },
        { value: 'février', text: 'février' },
        { value: 'mars', text: 'mars' },
        { value: 'avril', text: 'avril' },
        { value: 'mai', text: 'mai' },
        { value: 'juin', text: 'juin' },
        { value: 'juillet', text: 'juillet' },
        { value: 'aout', text: 'aout' },
        { value: 'septembre', text: 'septembre' },
        { value: 'octobre', text: 'octobre' },
        { value: 'novembre', text: 'novembre' },
        { value: 'décembre', text: 'décembre' }
      ],
      ListModelToExtract: [
        { value: 'standard', text: 'Exports Standards' },
        { value: 'vertical', text: 'Verticale' },
        { value: 'horizontal', text: 'Horizontale' }
      ],
      ListType: [{ value: 'pdf', text: 'pdf' }],
      typeModal: 'pdf'
    };
  },
  methods: {
    ...mapActions([
      'exportExcelFraisVisionFilialeTh',
      'exportPdfInvalidFraisVisionFraisTh'
    ]),
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    resetModal() {
      this.selectedModel = 'standard';
      this.error = null;
      this.typeModal = 'pdf';
    },

    async handleExportFileInvalid() {
      var bodyFormData = new FormData();
      let dataToUseBE = [];
      dataToUseBE = this.dataToUse.filter(k => {
        return k.projects.some(e => e.check == true);
      });
      if (dataToUseBE.length == 0) {
        dataToUseBE = this.dataToUse;
      }
      let indexToUse = 0;
      for (let j = 0; j < dataToUseBE.length; j++) {
        for (let i = 0; i < dataToUseBE[j].projects.length; i++) {
          if (
            this.filterType != null &&
            dataToUseBE[j].projects[i].check == true
          ) {
            if (
              dataToUseBE[j].projects[i][this.computedStatue] ==
              'facture non validée'
            ) {
              bodyFormData.append(
                'ids[' + [indexToUse] + ']',
                dataToUseBE[j].projects[i].id
              );
              indexToUse = indexToUse + 1;
            }
          }
        }
      }
      bodyFormData.append('type', this.computedColumn);

      const response = await this.exportPdfInvalidFraisVisionFraisTh(
        bodyFormData
      );
      if (response) {
        this.hideModal('extract-invalid-modal');
      }
    }
  },
  computed: {
    ...mapGetters(['getLoadingExtactBddFraisVisionFiliale']),
    computedStatue() {
      switch (this.filterType) {
        case 'NOVA':
          return 'statue_operationnel';
        case 'AXELIUS':
          return 'statue_marketing';
        case 'ISO 33':
          return 'statue_technique';
        case 'CERTILIA':
          return 'statue_rge';
        case 'GE CDG':
          return 'statue_CDG';
        case 'SAMI-B':
          return 'statue_administratif_sav';
        case 'WEB ABONDANCE':
          return 'statue_dsi';
        case 'CORPEDYS':
          return 'statue_juridique';
        case 'ALTEO France':
          return 'statue_alger';
        case 'NOVAE MAIRIE':
          return 'statue_mairie';
        case 'GSE':
          return 'statue_maintenance_pac';
        case 'UPCUBE TECHNOLOGIES':
          return 'statue_upcube';
        case 'Alteo contrôle qualité':
          return 'statue_alteo_control_gestion';
        default:
          return [
            'statue_operationnel',
            'statue_commercial',
            'statue_marketing',
            'statue_technique',
            'statue_rge',
            'statue_CDG',
            'statue_administratif_sav',
            'statue_dsi',
            'statue_juridique',
            'statue_alger',
            'statue_commercial',
            'statue_RH',
            'statue_bureau_detude',
            'statue_mairie',
            'statue_maintenance_pac',
            'statue_upcube',
            'statue_alteo_control_gestion'
          ];
      }
    },
    computedColumn() {
      switch (this.filterType) {
        case 'NOVA':
          return 'operationnel';
        case 'CACTUS':
          return 'commercial';
        case 'AXELIUS':
          return 'marketing';
        case 'ISO 33':
          return 'technique';
        case 'CERTILIA':
          return 'rge';
        case 'GE CDG':
          return 'CDG';
        case 'SAMI-B':
          return 'administratif_sav';
        case 'WEB ABONDANCE':
          return 'dsi';
        case 'CORPEDYS':
          return 'juridique';
        case 'ALTEO France':
          return 'alger';
        case 'NOVAE MAIRIE':
          return 'mairie';
        case 'GSE':
          return 'maintenance_pac';
        case 'UPCUBE TECHNOLOGIES':
          return 'upcube';
        case 'Alteo contrôle qualité':
          return 'alteo_control_gestion';
        default:
          return this.filterType;
      }
    }
  },
  mounted() {
    var courantYear = moment().year();
    for (var i = 2019; i <= courantYear; i++) {
      this.ListYear.push({ value: i, text: i });
    }
  }
};
</script>

<style scoped lang="scss"></style>
<style lang="scss" scoped>
.align-item-extract-modal {
  display: flex;
  .form-group {
    width: 50%;
  }
}
.modal-extaction-bdd-frais {
  .d-block,
  #fieldset-horizontal,
  #fieldset-horizontal-type {
    font-size: 12px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    text-align: start;
    color: #2a2a2a;
    margin-left: 3%;
    margin-top: 8px;
    margin-bottom: 1px;
  }
  .form-modal-custom-style {
    text-align: center;
  }
}

.button-export-style {
  background-color: #e4261b;
  &:hover,
  &:focus {
    background-color: #e4261b;
  }
}
</style>
